import { cn } from '@finn/ui-utils';
import React, { ReactNode } from 'react';

type Props = {
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
  className?: string;
  children?: ReactNode;
};

const SpacingBox = ({
  children,
  noPaddingTop,
  noPaddingBottom,
  className,
}: Props) => {
  return (
    <div
      className={cn(
        noPaddingBottom ? 'pb-0' : 'pb-14 sm:pb-16 md:pb-24',
        noPaddingTop ? 'pt-0' : 'pt-12 sm:pb-16 md:pt-24',
        className
      )}
    >
      {children}
    </div>
  );
};

export default SpacingBox;
