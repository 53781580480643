import { cn } from '@finn/ui-utils';
import React from 'react';

type Props = {
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
  children?: React.ReactNode;
};

const SmallSpacingBox = ({
  children,
  noPaddingTop,
  noPaddingBottom,
}: Props) => {
  return (
    <div
      className={cn(
        noPaddingBottom ? 'pb-0' : 'pb-8 sm:pb-10',
        noPaddingTop ? 'pt-0' : 'pt-8'
      )}
    >
      {children}
    </div>
  );
};

export default SmallSpacingBox;
