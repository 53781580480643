import { getLocaleFromContext } from '@finn/ui-utils';
import { GetStaticProps } from 'next';
import { ErrorProps } from 'next/error';
import React from 'react';

import ErrorPage from '~/components/ErrorPage';
import { ErrorPageData } from '~/types/cosmicModules';
import { getCachedCosmicObject } from '~/utils/cosmic';

export interface IProps extends ErrorProps {
  pageData: ErrorPageData;
}

export default function CustomErrorPage({ pageData }: IProps) {
  return <ErrorPage pageData={pageData} statusCode={404} error={null} />;
}

export const getStaticProps: GetStaticProps = async (ctx) => {
  const pageData = await getCachedCosmicObject({
    slug: 'static-error-page',
    locale: getLocaleFromContext(ctx),
  });

  return {
    props: {
      pageData,
    },
  };
};
